<template>
    <!-- 
        Компонент календарю, дозволяє - 
        - переглянути події в календарі
        - додати події в календарь
        - та багато іншого :)
    -->

    <!-- Шапка календарю -->
    <div class="card">
        <div class="card-body bg-soft-info">
            <div class="d-flex">
                <div class="flex-shrink-0">
                    <CalendarIcon
                        class="text-info icon-dual-info"
                    ></CalendarIcon>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="fs-15">{{ $t('WelcometoYourCalendar') }}</h6>

                    <!-- <p class="text-muted mb-0">
                        {{ $t('EventThatApplicationsBook') }}
                    </p> -->
                </div>
                <div class="flex-grow-4 ms-3">
                    <!-- Фільтр -->
                    <Filter 
                        v-if="objParams && (dataParams && dataParams.status == true)" 
                        :dataParams="dataParams" 
                        :objParams="objParams" 
                        @changeFilter="changeFilter" 
                        @clearFilter="clearFilter"  
                    />
                </div>
            </div>
        </div>
    </div>
    
    <!-- Головний компонент для управління подіями та календарем -->
    <b-row>
        <b-col lg="12">
            <b-row>

                <!-- Ліва частина екрану, де відображаються опції та найближчі події -->
                <b-col xl="3" >
                    <!-- 
                        Блок додавання нової події
                        - йде перевірка на права доступу 421 (створення події) або 2000 (режим бога)
                    -->
                    <b-card v-if="perms[421] || perms[2000]">
                        <button class="btn btn-primary w-100" id="btn-new-event" @click="showCreateModal = true">
                            <i class="mdi mdi-plus"></i> {{ $t('CreateNewEvent') }}
                        </button>

                        <!-- 
                            Блок доступу до типів подій
                            - йде перевірка на права доступу 424 (редагування доступу до типу події) або 2000 (режим бога)
                        -->
                        <hr v-if="perms[424] || perms[2000]" />
                        <div v-if="perms[424] || perms[2000]" id="external-events">
                            <p 
                                class="text-muted copy" 
                                @click="showTypes = !showTypes"
                            >
                                {{ $t('typeEventsSetting') }} <i class="mdi mdi-plus"></i>
                            </p>

                            <!-- Відображення типів подій -->
                            <div v-if="showTypes" v-for="item in typeEvents" :key="item" :class="`external-event bg-soft-${item.color} text-${item.color} d-flex`" >
                                <div class="flex-grow-1 mt-1" >
                                    <i class="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i>
                                    {{ item.title }}
                                </div>
                                <div class="flex-shrink-0" >
                                    <button type="button" @click="getWorkers(item)" :class="`btn btn-soft-${item.color} btn-sm`">
                                        <i class="bx bx-user-plus align-bottom fs-14"></i>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </b-card>
                   
                    <!-- Відображення найближчих подій -->
                    <div class="comingEvent">
                        <h5 class="mb-1">{{ $t('UpcomingEvents') }}</h5>
                        <p class="text-muted">{{ $t('DontmissScheduledevents') }}</p>
                        <div
                            class="upcoming-events"
                        >
                            <!-- Карточки найближчих подій -->
                            <div
                                class="card mb-3"
                                v-for="event in currentEvents"
                                :key="event.id"
                                >
                                <div class="card-body copy" @click="openEvent(event)">
                                    <!-- Інформація про подію -->
                                    <div class="d-flex mb-3" >
                                        <div class="flex-grow-1" >
                                            <i class="ri-checkbox-blank-circle-fill me-1 text-info pb-4"></i>
                                            <span class="fw-medium">{{this.formatDate(event.date)}}</span>
                                        </div>
                                        <div class="flex-shrink-0" >
                                            <small class="badge badge-soft-secondary ms-auto">{{ event.time }}</small>
                                        </div>
                                    </div>
                                    <h6 class="card-title fs-18 fw-semibold">{{ event.name }}</h6>
                                    <p class="text-muted text-truncate-two-lines mb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Дні народження -->
                    <div class="comingEvent">
                        <h5 class="mb-1">{{ $t('Birthdays') }}</h5>
                        <p class="text-muted">{{ $t('DonMisstheDaysofHonoringColleagues') }}</p>
                        <div
                            class="upcoming-events"
                        >
                            <!-- Карточки найближчих подій -->
                            <div
                                class="card mb-3"
                                v-for="event in birthdayEvents"
                                :key="event.id"
                                >
                                <div class="card-body copy" @click="openEvent(event)">
                                    <!-- Інформація про подію -->
                                    <div class="d-flex mb-3" >
                                        <div class="flex-grow-1" >
                                            <i class="ri-checkbox-blank-circle-fill me-1 text-info pb-4"></i>
                                            <span class="fw-medium">{{this.formatDate(event.date)}}</span>
                                        </div>
                                        <div class="flex-shrink-0" >
                                            <small class="badge badge-soft-secondary ms-auto">{{ event.time }}</small>
                                        </div>
                                    </div>
                                    <h6 class="card-title fs-18 fw-semibold">{{ event.name }}</h6>
                                    <p class="text-muted text-truncate-two-lines mb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>

                <!-- Права частина, відображення календарю -->
                <b-col xl="9">
                    <b-card>
                        <FullCalendar :options="calendarOptions" ref="fullCalendar"/>
                    </b-card>
                </b-col>

            </b-row>
        </b-col>
    </b-row>

    <!-- 
        Виклик вікна створення події
        - для відкриття застосовується перевірка значення showCreateModal
        - @close - подія, яка спрацьовує при закритті картки
        - @getdata - подія, яка спрацьовує при отриманні даних для календарю
        - :newEventData - параметр дати, передається дата/день кліку
        - :obj - параметри події, необхідно для редагування
        - :events - параметри подій
        - :perm - параметри режим редагування
    -->
    <created
      v-if="showCreateModal"
      @close="closeCreate"
      @getdata="getdata"
      :newEventData="newEventData"
      :obj="this.form"
      :events="this.calendarOptions.events"
      :perm="this.permToEdit"
    />

    <!-- 
       Виклик відкриття картки події
       - для відкриття застосовується перевірка значення showOpenModal
       - @close - подія, яка спрацьовує при закритті картки
       - :obj - параметр-об'єкт з даними по події
       - @getdata - подія, яка спрацьовує при отриманні даних для календарю
       - @edit - подія, для редагування картки події
    -->
    <viewBox 
      v-if="showOpenModal"
      @close="closeModal"
      :obj="this.objEvent"
      @getdata="getdata"
      @edit="editEvent"
    />
    
    <!-- 
       Виклик модального вікна з списком співробітників
       - використовується для надання доступу до типів подій 
       - @invite - збереження прав доступу до типу події
       - :projectWorkers - співробітники які додані до типу події
    -->
    <modal
        v-if="showModal"
        @close="showModal = false"
        @invite="invite"
        :projectWorkers="eventAccess.workers"
    />

</template>

<script>
// імпорт бібліотек та компонентів для використання FullCalendar
import "@fullcalendar/core/vdom";
import { CalendarIcon } from "@zhuowenli/vue-feather-icons";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import FullCalendar from "@fullcalendar/vue3";
import ruLocale from '@fullcalendar/core/locales/ru';
import ukLocale from '@fullcalendar/core/locales/uk';

import created from './create' // картка створення події
import viewBox from './viewEvent' // картка події
import modal from '@/components/modal/modal-members' // компонент додавання співробітників
import { storeS } from '@/store' // глобальне сховище даних
import { DataCalendar } from '@/API' // клас для взаємодії з API 
import Filter from "../globaltable/globalFilter.vue"; // фільтр до календаря
import { mutateTypeCalendar } from "../../usabilityScripts/globalMutate";
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new DataCalendar();

export default{
    components: {
        FullCalendar,
        CalendarIcon,
        created,
        modal,
        viewBox,
        Filter
    },
    data(){
        return{
            showTypes: false, // прапорець відображення типів події
            dataParams: { // Параметри для фільтрації
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "calendar"
            },
            objParams:{ // Параметри для фільтрації даних
                date: '',
                time: '',
            },
            showModal: false, // прапорець відображення вікна списку співробітників
            eventAccess: { // об'єкт доступу до події
                type: '', // тип події
                workers: [] // масив співробітників
            },
            showCreateModal: false, // прапорець відображення вікна створення події
            showOpenModal: false, // прапорець відображення вікна події
            form: {}, // параметри події
            permToEdit: false, // прапорець режиму редагування події
            calendarOptions: { // параметри для відображення календрарю
                locale: ukLocale, // локалізація
                events: [], // масив подій
                start: null,
                end: null,
                activeDate: null,
                plugins: [
                    dayGridPlugin, // плагін перегляду подій по дням
                    timeGridPlugin, // плагін перегляду події по годинам
                    interactionPlugin, // плагін для відслідковування кліків, розмірів та інше
                    bootstrapPlugin, // тема календаря
                    listPlugin, // плагін перегляд списку відображає події у простому вертикальному списк
                ],
                themeSystem: "bootstrap", // тема календаря
                headerToolbar: { // // кнопки для перемикання між переглядами
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                },
                windowResize: () => { // автоматична адаптація під потрібний розмір
                    this.getInitialView();
                },
                initialView: this.getInitialView(), // початковий вигляд календаря
                initialEvents: [],
                editable: true, // прапорець чи можна змінювати події в календарі
                // eventDragStart: this.handleEventDragStart, // Перевірка, чи можна переміщати подію
                eventAllow: (dropInfo, draggedEvent) => {
                    // Використовуємо extendedProps для перевірки
                    return draggedEvent.extendedProps.isDraggable !== false;
                },
                selectable: true, // прапорець щоб дозволити користувачу робити вибір, клацаючи та перетягуючи
                selectMirror: true,
                dayMaxEvents: true, 
                weekends: true, // вихідні дні
                dateClick: this.dateClicked, // запускається коли користувач натискає на дату або час
                eventClick: this.openEvent, // запускається коли користувач натискає на подію
                eventChange: this.moveEvent, // викликається після певної зміни події
            },
            currentEvents: [], // масив найближчих подій
            // howModal: false,
            // eventModal: false,
            // submitted: false,
            // submit: false,
            newEventData: '', // Дата створення (при кліку на день)
            edit: {},
            deleteId: {},
            event: {
                title: "",
                category: "",
            },
            editevent: {
                editTitle: "",
                editcategory: "",
            },
            obj: {},
            objEvent: {},
            typeEvents: [
                // Типи подій
                {
                    title: this.$t('presentation'),
                    color: 'info',
                    value: 'presentation'
                },
                {
                    title: this.$t('connectionDevice'),
                    color: 'info',
                    value: 'connection'
                },
                {
                    title: this.$t('Task'),
                    color: 'danger',
                    value: 'task'
                },
                {
                    title: this.$t('Meeting'),
                    color: 'success',
                    value: 'meeting'
                },
                {
                    title: this.$t('deadLine'),
                    color: 'danger',
                    value: 'deadline'
                },
                {
                    title: this.$t('bDay'),
                    color: 'success',
                    value: 'bday'
                },
                {
                    title: this.$t('DayOff'),
                    color: 'warning',
                    value: 'dayoff'
                },
                {
                    title: this.$t('Graphs'),
                    color: 'warning',
                    value: 'vacation',
                    mutate: (item) => this.mutateColor(item)
                },
                {
                    title: this.$t('Other'),
                    color: 'secondary',
                    value: 'other'
                },
                {
                    title: this.$t('private'),
                    color: 'secondary',
                    value: 'private'
                }
            ],
            birthdayEvents: [] // список днів наодження
        }
    },
    created(){
        // ... ініціалізація компонента при створенні
        if(storeS.lang == 'uk'){
            this.calendarOptions.locale = ukLocale
        } else if(storeS.lang == 'ru'){
            this.calendarOptions.locale = ruLocale
        }

        // отримання даних для календаря
        this.getdata()
    },
    methods: {
        mutateColor(e) {
            /**
                * @param {object} e - тип 
            */

            console.log(e)
        },
        changeFilter(c,d) { 
            /**
                * Змінити фільтр - метод фільтрації подій на основі дати та часу.
                * @param {string} c - повна дата.
                * @param {string} d - дата або час.
            */

            if(d.split('-').length == 3) {
                this.gotoPast(d)
            }
        },
        gotoPast(e) {
            /**
                * Перехід в календарі до певної дати.
                * @param {string} e - дата фільтру.
            */

            let calendarApi = this.$refs.fullCalendar.getApi()
            calendarApi.gotoDate(e); // виклик методу об'єкта Calendar
        },
        getdata(){
            /**
                Отримує дані про події з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Object} objParams - параметри для отримання даних
            */

            apiServe.getEvents(this.objParams).then(result => {

                this.calendarOptions.events = [];

                if(result.status == 'done'){
                    
                    // масив найближчих подій
                    this.currentEvents = result.data.upcomingEvents
                    this.birthdayEvents = result.data.birthdayEvents

                    for(var item in result.data.events){
                        const date = new Date(result.data.events[item].toDate);
                        date.setDate(date.getDate() + 1);
                        var dateStr = "";
                        if(result.data.events[item].toDate){
                            dateStr =
                                date.getFullYear() + "-" +
                                ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                                ("00" + (date.getDate())).slice(-2);
                        } else {
                            var dateEnd = new Date(result.data.events[item].date+"T"+result.data.events[item].time)
                            dateStr = 
                                dateEnd.getFullYear() + "-" +
                                ("00" + (dateEnd.getMonth() + 1)).slice(-2) + "-" +
                                ("00" + (dateEnd.getDate())).slice(-2) + "T" +
                                ("00" + dateEnd.getHours()).slice(-2) + ":" +
                                ("00" + dateEnd.getMinutes()).slice(-2) + ":" +
                                ("00" + dateEnd.getSeconds() + 1).slice(-2);
                                // ("00" + dateEnd.getSeconds()).slice(-2);
                        }
                        this.calendarOptions.events.push(
                            {
                                id: result.data.events[item].id,
                                title: result.data.events[item].name,
                                start: result.data.events[item].date+"T"+result.data.events[item].time,
                                end: dateStr,
                                description: result.data.events[item].description,
                                idOper: result.data.events[item].document.id,
                                typeDoc: result.data.events[item].document.type,
                                type: result.data.events[item].type,
                                status: result.data.events[item].status,
                                className: this.mutateType(result.data.events[item].type, result.data.events[item]).color,
                                idWorker: result.data.events[item].createWorkerId,
                                nameWorker: result.data.events[item].createWorkerName,
                                holidayStatus: result.data.events[item].holidayStatus,
                                isDraggable: result.data.events[item].type == 'bday' ? false : true,
                            }
                        )
                    }
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка отримання всіх подій'; 
                    var statusRequest = result.status;
                    var api = 'getEvents';
                    var fileRequest = 'src/components/calendar/index.vue';
                    var params = this.objParams;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        getWorkers(item){
            /**
                * Отримати працівників на основі вибраного типу події.
                * Елемент @param {Object} – вибраний об’єкт типу події.
            */

            apiServe.getWorkers(item.value).then(result => {
                if(result.status == 'done'){
                    this.eventAccess.type = item.value;
                    this.eventAccess.workers = result.data.workers;
                    this.showModal = true
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "value": item.value,
                        "object":  item
                    }

                    var errorText = 'Помилка отримання співробітників до події'; 
                    var statusRequest = result.status;
                    var api = 'getWorkers';
                    var fileRequest = 'src/components/calendar/index.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        invite(workersList){
            /**
                * Invite - Метод для збереження прав вибраним працівникам на подію.
                * @param {Object} workersList - Список працівників.
            */

            var list = {
                workers: []
            };

            // Підготовка списку ідентифікаторів працівників з вибраних workersList
            for(var item in workersList.workers) {
                list.workers.push(
                    { workerId: workersList.workers[item].workerId }
                )
            }
            
            // Перевірка, чи вказаний тип події
            if (!this.eventAccess.type) {
                this.$toast.error(this.$t('EnterEvent'));
            }

            // Якщо вказаний тип події і є вибрані працівники, зберігаємо налаштування
            if(this.eventAccess.type && list.workers.length > 0){
                apiServe.saveWorkers(this.eventAccess.type, list).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('appointed'));
                        this.getdata();
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var tempObj = {
                            "type": this.eventAccess.type,
                            "list": list
                        }

                        var errorText = 'Помилка збереження доступу до типів подій'; 
                        var statusRequest = result.status;
                        var api = 'saveWorkers';
                        var fileRequest = 'src/components/calendar/index.vue';
                        var params = tempObj;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        },
        formatDate(date) {
            /**
                * Метод для форматування дати в зручний рядок.
                * @param {string} date - Дата для форматування.
                * @returns {string} - Форматований рядок дати.
            */

            var monthNames = [
                this.$t('January'),
                this.$t('February'),
                this.$t('March'),
                this.$t('April'),
                this.$t('May'),
                this.$t('June'),
                this.$t('July'),
                this.$t('August'),
                this.$t('September'),
                this.$t('October'),
                this.$t('November'),
                this.$t('December'),
            ];
            var d = new Date(date),
                month = "" + monthNames[d.getMonth()],
                day = "" + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            return [day + " " + month, year].join("  ");
        },
        mutateType(e, item) {
            /*
                Функція для застосування перекладу до типу події
            */
            return mutateTypeCalendar(e, item)
        },
        getInitialView() {
            /**
                * Метод для отримання початкового вигляду календаря.
                * @returns {string} - Початковий вигляд календаря залежно від ширини вікна.
            */

            if (window.innerWidth >= 768 && window.innerWidth < 1200) {
                return "timeGridWeek";
            } else if (window.innerWidth <= 768) {
                return "listMonth";
            } else {
                return "dayGridMonth";
            }
        },
        moveEvent(info){
            /**
                * Метод для переміщення події
                * @returns {Object} info - параметри події
            */

            // console.log("f", info);
            if(info.event.extendedProps.type == "bday"){
                return
            }
            

            var obj = info.event
            var id = obj.id

            var form = {
                name: obj.title,
                type: obj.extendedProps.type,
                status: obj.extendedProps.status,
                date: this.moveDateTime(obj.startStr).dateStr,
                toDate: obj.endStr != "" ? this.moveDateTime(obj.endStr).dateStr : '',
                time: this.moveDateTime(obj.startStr).timeStr,
                description: obj.extendedProps.description
            }
            apiServe.editEvent(id, form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'));
                    this.getdata();
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "id": id,
                        "params": form
                    }

                    var errorText = 'Помилка редагування події'; 
                    var statusRequest = result.status;
                    var api = 'deleteFine';
                    var fileRequest = 'src/components/calendar/index.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        moveDateTime(date){
            /**
                * Метод для перетворення об'єкта дати/часу в рядок дати та рядок часу.
                * @param {string} date - Рядок дати/часу для перетворення.
                * @returns {Object} - Об'єкт із рядком дати та рядком часу.
            */

            var date3 = new Date(date);  
            var dateStr = date3.getFullYear() + "-" + ("00" + (date3.getMonth() + 1)).slice(-2) + "-" + ("00" + date3.getDate()).slice(-2);
            var timeStr = ("00" + date3.getHours()).slice(-2) +":"+("00" + date3.getMinutes()).slice(-2)
            return { dateStr, timeStr};
        },
        dateClicked(info) {
            /**
                * Метод для обробки кліку на дату у календарі.
                * @param {Object} info - Об'єкт із інформацією про клікнуту дату.
            */

            this.newEventData = info.dateStr;
            this.showCreateModal = true;
        },
        editEvent(e) {
            /**
                * Метод для редагування вибраної події.
                * @param {Object} e - Об'єкт події для редагування.
            */

            this.showOpenModal = false;
            this.permToEdit = true;
            this.form = e;
            this.showCreateModal = true
        },
        openEvent(info){
            console.log(info)
            /**
                Функція для відкриття модального вікна з інформацією про подію.
                * @param {Object} info - об'єкт картки події

                Результат присвоюється властивості objEvent, та встановлюється прапорці showOpenModal в true для відображення модального вікна.
            */

            this.obj = {};
            if (info.event) {
                this.obj = info.event;
            } else {
                this.obj.id = info.id;
                this.obj.title = info.name;
                this.obj.startStr = info.date+"T"+info.time;
                this.obj.extendedProps = {
                    description: info.description,
                    nameWorker: info.createWorkerName,
                    type: info.type,
                    status: info.status,
                    idDoc: info.document.id,
                    idType: info.document.type,
                    holidayStatus: info.holidayStatus
                }
            }
            
            this.objEvent = this.obj
            this.showOpenModal = true
        },
        closeModal(){
            /*
                Функція для закриття модального вікна.
                Встановлює прапорці showOpenModal в false, та викликає функцію getdata для оновлення даних.
            */

            storeS.checkModal.type = '';
            storeS.checkModal.id = '';
            this.showOpenModal = false
            this.getdata();
        },
        closeCreate(){
            /*
                Функція для закриття модального вікна на створення події.
                Встановлює прапорці permToEdit та showCreateModal в false, та викликає функцію getdata для оновлення даних.
            */

            this.permToEdit = false;
            this.showCreateModal = false
            this.getdata();
        }
    },
    mounted() {
        // Cлухання події updateEvents для оновлення даних після змін з карткою події.
        this.eventBus.off('updateEvents');
        this.eventBus.on('updateEvents', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        }
    }
}

</script>

<style>
.comingEvent {
    /* height: 600px; */
    /* height: calc(100% - 30px); */
    max-height: 600px;
    overflow: auto;
    margin-bottom: 20px;
}

.comingEvent::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .comingEvent::-webkit-scrollbar
    {
        
        height: 5px;
        background-color: #F5F5F5;
    }

    .comingEvent::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #302626af;
    }

</style>